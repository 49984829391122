/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from 'react-router';
import { getSessionLink, getSessionShareLink } from "../Sessions/SessionUtils";
import { JOIN_SESSION_BEFORE_START_PERIOD_MIN, OPEN_SESSION_SETTINGS_OPTIONS, USER_SESSION_STATUS } from '../../functions/shared/constants'
import { UserContext, usePreferences } from '../UserProvider';
import { useBookSessionButtonHandler } from '../bookingLogic/useBookSessionButtonHandler';
import { sessionLimitTooltip } from '../Sessions/sessionLimitTooltip';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { TooltipWrapper } from './TooltipWrapper';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { BRONZE, FC_GREEN } from '../emotionVariables';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { copyToClipboard, openInSessionPage } from '../utils';
import { RequestSessionModal } from '../Sessions/modals/RequestSessionModal';
import { LoginSignupModal } from '../Sessions/modals/LoginSignupModal';
import { OutOfSessionProgressBars } from '../ParticipantApp/SessionStageProgressBars';


const useJoinNowNonUser = ({ onSuccess }) => {
  const { setActiveModal } = useModal()

  const joinNowClickedByNonUser = async () => {
    setActiveModal(LoginSignupModal, {
      onSuccess: () => {
        setActiveModal(null)
        onSuccess()
      },
      onHide: () => { setActiveModal(null) }
    })
  }

  return joinNowClickedByNonUser
}

const ConfirmAndBookSessionModal = ({ event, bookSessionClicked, onClose }) => {
  const { eventTypeId, agenda, title, description } = event
  return (
    <Modal
      show
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h2">Confirm and Book</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This is a <b>{title}</b> themed session for <b>{eventTypeId}</b>. Please read the agenda and rules, and only book if you agree!</p>
        <OutOfSessionProgressBars stages={agenda} />
        <div css={css`margin-top: 16px;`}>
          {description}
        </div>
        <div css={css`margin-top: 16px; display: flex; gap: 16px;`}>
        <Button onClick={bookSessionClicked}>Yes, book it!</Button>
        <Button onClick={onClose} variant="link">Nevermind</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const useBookWithConfirmationButtonHandler = ({ event, bookSessionClicked }) => {
  const { setActiveModal } = useModal()

  const bookSessionWithConfirmationClicked = () => {
    setActiveModal(ConfirmAndBookSessionModal, { event, bookSessionClicked, onClose: () => { setActiveModal(null) }})
  }

  return bookSessionWithConfirmationClicked
}



export function BookingButtonWrapper({ event, onActionSuccess = () => {}, showInviteGuestsLink = false }) {
  const sessionLink = getSessionLink(event.id)
  const history = useHistory()
  const { user } = useContext(UserContext)
  const { openSessionSetting } = usePreferences()
  const inviteCode = user !== null ? user.inviteCode : undefined
  const privateSession = event.privateSession || false
  const joinBookedSession = (e) => {
    switch (openSessionSetting) {
      case OPEN_SESSION_SETTINGS_OPTIONS.NEW_TAB:
        window.open(sessionLink, '_blank')
        break
      case OPEN_SESSION_SETTINGS_OPTIONS.CURRENT_TAB:
        history.push(`/s/${event.id}`)
        break;
      default:
        openInSessionPage(sessionLink)
    }
  }

  const joinNowNonUserClicked = useJoinNowNonUser({
    onSuccess: () => { history.push(`/s/${event.id}`) }
  }) 

  const bookSessionClicked = useBookSessionButtonHandler(event, onActionSuccess)

  const bookSessionWithConfirmationClicked = useBookWithConfirmationButtonHandler({ event, bookSessionClicked })

  const requestAndBookSessionClicked = useBookSessionButtonHandler(event, onActionSuccess, (user, event, setActiveModal, onSuccess) => {
    // sendSegmentEvent("Request and Book Host TBD Session Clicked")
    setActiveModal(RequestSessionModal, { requestTime: event.start.getTime(), onSuccess })
  })

  const inviteGuestsClicked = () => {
    const sessionShareLink = getSessionShareLink(event.id, inviteCode)
    copyToClipboard(sessionShareLink, { onSuccessMessage: "Session link copied!"})
  }

  const getButtonRenderProperties = (userSessionStatus) => {
    switch (userSessionStatus) {
      case USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_LOADING:
        return {
          onClick: null,
          tooltip: null,
          buttonText: "Just a sec..."
        }
      case USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_NON_USER:
        return {
          onClick: joinNowNonUserClicked,
          tooltip: null,
          buttonText: "Join now",
          extraButtonProps: {
            css: css`background-color: ${FC_GREEN}; border-color: ${FC_GREEN};`
          },
        }
      case USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW:
        return {
          onClick: joinBookedSession,
          tooltip: null,
          buttonText: "Join now",
          extraButtonProps: {
            css: css`background-color: ${FC_GREEN}; border-color: ${FC_GREEN};`
          },
        }
      case USER_SESSION_STATUS.NOT_JOINABLE_HAPPENING_NOW:
        return {
          onClick: null,
          tooltip: inProgressTooltip,
          buttonText: "Happening now",
          extraButtonProps: { variant: "light" },
        }
      case USER_SESSION_STATUS.PARTICIPANT_PAST_SESSION:
      case USER_SESSION_STATUS.HOST_PAST_SESSION:
        return {
          onClick: null,
          tooltip: null,
          buttonText: "Completed!",
          extraButtonProps: {
            variant: "light",
            disabled: true,
          },
        }
      case USER_SESSION_STATUS.NOT_JOINABLE_PAST:
        return {
          onClick: null,
          tooltip: null,
          buttonText: "Missed it!",
          extraButtonProps: {
            variant: "light",
            disabled: true
          }
        }
      case USER_SESSION_STATUS.HOST_HAPPENING_NOW:
      case USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE:
        return {
          onClick: joinBookedSession,
          tooltip: null,
          buttonText: "Start the session →",
          extraButtonProps: { variant: "secondary" }
        }
      case USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW:
      case USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE:
        return {
          onClick: joinBookedSession,
          tooltip: null,
          buttonText: "Join the session →",
          extraButtonProps: { variant: "success" }
        }
      case USER_SESSION_STATUS.HOST_FUTURE_SESSION:
        return {
          onClick: showInviteGuestsLink ? inviteGuestsClicked : null,
          tooltip: showInviteGuestsLink ? 'Copy a link to this session' : confirmedTooltip,
          buttonText: showInviteGuestsLink ? "Invite Guests" : "Hosting",
          extraButtonProps: {
            variant: "secondary",
            disabled: false,
            css: privateSession ? css`background-color: ${BRONZE}; border-color: ${BRONZE};` : undefined
          }
        }
      case USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION:
        return {
          onClick: null,
          tooltip: confirmedTooltip,
          buttonText: "Joining",
          extraButtonProps: {
            variant: !privateSession ? "success" : undefined,
            disabled: false,
            css: privateSession ? css`background-color: ${BRONZE}; border-color: ${BRONZE};` : undefined
          }
        }
      case USER_SESSION_STATUS.NOT_JOINABLE_FULL:
        return {
          onClick: null,
          tooltip: sessionLimitTooltip,
          buttonText: "Session full",
          extraButtonProps: { variant: "light", disabled: true }
        }
      case USER_SESSION_STATUS.NOT_JOINABLE_UPDATE_CARD:
        return {
          onClick: null,
          tooltip: null,
          buttonText: "Update billing info",
          extraButtonProps: {
            variant: "secondary",
            href: "/settings/billing",
            disabled: false
          }
        }
      case USER_SESSION_STATUS.NOT_JOINABLE_BAD_SUBSCRIPTION_STATE:
        return {
          onClick: null,
          tooltip: null,
          buttonText: "See plans",
          extraButtonProps: {
            variant: "secondary",
            href: "/plans",
            disabled: false
          }
        }
      case USER_SESSION_STATUS.NOT_JOINABLE_NOT_FOR_FIRST_TIMERS:
        return {
          onClick: null,
          tooltip: notForFirstTimersTooltip,
          buttonText: "Locked",
          extraButtonProps: { variant: "light", disabled: true }
        }
      case USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION:
        return {
          onClick: bookSessionClicked,
          tooltip: null,
          buttonText: "Book"
        }
      case USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUEST:
        return {
          onClick: requestAndBookSessionClicked,
          tooltip: null,
          buttonText: "Book"
        }
      case USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUIRES_CONFIRMATION:
        return {
          onClick: bookSessionWithConfirmationClicked,
          tooltip: null,
          buttonText: "Book..."
        }
    }
  }

  const { userSessionStatus } = event
  const { onClick, tooltip, buttonText, extraButtonProps = {} } = getButtonRenderProperties(userSessionStatus)
  
  const sendSegmentEvent = useSendSegmentEvent()
  const bookingButtonClicked = (e) => {
    sendSegmentEvent("Booking Button Clicked", { buttonState: userSessionStatus, eventId: event.id, showInviteGuestsLink, privateSession })

    if (extraButtonProps.href === undefined) {
      e.preventDefault()
    } else {
      e.stopPropagation()
    }
    if (onClick !== null) {
      e.stopPropagation()
      onClick()
    }
  }

  return (
    <BookingButton onClick={bookingButtonClicked} tooltip={tooltip} buttonText={buttonText} extraButtonProps={extraButtonProps} />
  )
}



function BookingButton({ onClick, tooltip, buttonText, extraButtonProps }) {
  const buttonDisabled = onClick === null && extraButtonProps.disabled !== false // this looks extremely weird but just roll with it

  return (
    <TooltipWrapper TooltipContents={tooltip} useFloatingArgs={{ placement: 'top', middleware: [shift({ padding: 32 }), offset(12), flip()] }}>
      <Button type="submit" onClick={onClick} disabled={buttonDisabled} css={buttonDisabled ? css`pointer-events: none;` : null} {...extraButtonProps}>
        {buttonText}
      </Button>
    </TooltipWrapper>
  )
}

const confirmedTooltip = `Check your cal/email for the invite! You can also access the session directly from here within ${JOIN_SESSION_BEFORE_START_PERIOD_MIN} minutes of the start time.`

const inProgressTooltip = "The magic is already happening! Book a different time to get into flow too."

const notForFirstTimersTooltip = "We recommend booking a different session first before you try a longer chat-only session."