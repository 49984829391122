/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  useEffect,
  useContext,
  useReducer,
  useState,
} from 'react';
import Tile from '../Tile/Tile';
import { SessionToastForCallState } from '../SessionToast';
import { useCPULoad } from '@daily-co/daily-react';
import {
  initialCallState,
  CLICK_ALLOW_TIMEOUT,
  PARTICIPANTS_CHANGE,
  ACTIVE_SPEAKER_CHANGE,
  CAM_OR_MIC_ERROR,
  CPU_ERROR,
  FATAL_ERROR,
  callReducer,
  isLocal,
  isScreenShare,
  isLocalScreenShare,
  isActiveSpeaker,
} from './callState';
import { logDailyEvent } from '../../logUtils';
import EventContext from '../../../EventContext';
import { UserContext } from '../../../UserProvider';

import useSound from 'use-sound';
import EnterSound from './enter.wav'
import { useRerenderOnScreenResize } from '../../../utils';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';

const dailySendEncodings = {
  "low": {
    "maxBitrate": 90000,
    "scaleResolutionDownBy": 2,
    "maxFramerate": 15
  },
  "medium": {
    "maxBitrate": 200000,
    "scaleResolutionDownBy": 2,
    "maxFramerate": 15
  },
  "high": {
    "maxBitrate": 600000,
    "scaleResolutionDownBy": 1,
    "maxFramerate": 30
  }
}

export default function Call({ isMusicMuted, activeWorkingTime, sessionWorkingTimeFinished, hidingSelfView, mirrorSelfView }) {
  const [callState, dispatch] = useReducer(callReducer, initialCallState);
  const { event: fcEvent, callObject, demoSession } = useContext(EventContext);
  const { user } = useContext(UserContext)

  const isHost = fcEvent && user ? fcEvent.hostId === user.uid : false
  const [play] = useSound(EnterSound)
  const sendSegmentEvent = useSendSegmentEvent()

  const onCPULoadChange = ({ cpuLoadState, cpuLoadStateReason }) => {
    if (cpuLoadState === 'high' || cpuLoadState === undefined) {
      dispatch({
        type: CPU_ERROR,
        message: "High CPU Usage",
      })
      sendSegmentEvent('Hit High CPU Usage', { cpuLoadStateReason, cpuLoadState })
    }
  }

 useCPULoad({ onCPULoadChange })

 // TODO: Listen for and dispatch network error

  /**
   * Start listening for participant changes, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    const events = [
      'participant-joined',
      'participant-updated',
      'participant-left',
    ];

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      dispatch({
        type: PARTICIPANTS_CHANGE,
        participants: callObject.participants(),
      });
      if (event && isHost && event.action === 'participant-joined' && activeWorkingTime) {
        play()
      }
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewParticipantsState);
      }
    };
  }, [callObject, isHost, play, activeWorkingTime]);

  useEffect(() => {
    if (!callObject) return;

    function handleActiveSpeakerChange(event) {
      event && logDailyEvent(event);
      dispatch({
        type: ACTIVE_SPEAKER_CHANGE,
        speakerId: (event && event.activeSpeaker && event.activeSpeaker.peerId) || 'Unknown',
      })
    }

    callObject.on('active-speaker-change', handleActiveSpeakerChange)

    return function cleanup() {
      callObject.off('active-speaker-change', handleActiveSpeakerChange)
    }
  })

  /**
   * Start listening for call errors, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleCameraErrorEvent(event) {
      logDailyEvent(event);
      dispatch({
        type: CAM_OR_MIC_ERROR,
        message:
          (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.

    callObject.on('camera-error', handleCameraErrorEvent);

    return function cleanup() {
      callObject.off('camera-error', handleCameraErrorEvent);
    };
  }, [callObject]);

  /**
   * Start listening for fatal errors, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleErrorEvent(e) {
      logDailyEvent(e);
      dispatch({
        type: FATAL_ERROR,
        message: (e && e.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    callObject.on('error', handleErrorEvent);

    return function cleanup() {
      callObject.off('error', handleErrorEvent);
    };
  }, [callObject]);

  /**
   * Start a timer to show the "click allow" message, when the component mounts.
   */
  useEffect(() => {
    const t = setTimeout(() => {
      dispatch({ type: CLICK_ALLOW_TIMEOUT });
    }, 2500);

    return function cleanup() {
      clearTimeout(t);
    };
  }, []);

  useEffect(() => {
    if (!callObject || callObject.meetingState() !== 'joined-meeting') return
    if (activeWorkingTime) {
      callObject.updateReceiveSettings({
        '*': { video: { layer: 0 } }
       });
      callObject.updateSendSettings({
        'video': {
          maxQuality: 'low',
          encodings: dailySendEncodings,
        }
      })
    } else {
       callObject.updateSendSettings({
        'video': {
          maxQuality: 'high',
          encodings: dailySendEncodings,
        }
      })
      callObject.updateReceiveSettings({
        '*': { video: { layer: 2 } }
      })
    }
  }, [activeWorkingTime, sessionWorkingTimeFinished, callObject])

  const [desiredVideoWidth, setDesiredVideoWidth] = useState(0)

  function getTiles() {
    let largeTiles = [];
    let audioTiles = [];
    Object.entries(callState.callItems).forEach(([id, callItem]) => {
      if (demoSession && id !== "local") { return }

      const tile = (
        <div css={css`
          width: ${desiredVideoWidth}px;
          ${isLocal(id) && mirrorSelfView && css`video { transform: scale(-1, 1); }`}
        `}>
          <Tile
            key={id}
            sessionId={id}
            userId={callItem.userId}
            videoTrackState={callItem.videoTrackState}
            audioTrackState={callItem.audioTrackState}
            isLocalPerson={isLocal(id)}
            hidingSelfView={isLocal(id) && hidingSelfView}
            isLarge={true}
            isScreenShare={isScreenShare(id)}
            isLocalScreenShare={isLocalScreenShare(id)}
            disableCornerMessage={isScreenShare(id)}
            showOverlay={true}
            isJoinScreen={callState.callItems.length === 1}
            isActiveSpeaker={isActiveSpeaker(callState, id)}
          />
        </div>
      );

      if (isScreenShare(id)) {
        audioTiles.push(tile);
      } else {
        largeTiles.push(tile);
      }
    });

    return [largeTiles, audioTiles];
  }

  const GAP_SPACE_BETWEEN_TILES = 12
  const onTilesContainerRender = (element) => {
    if (element !== null) {
      const availableSpace = element.getBoundingClientRect()
      const { width, height } = availableSpace
      const videoAspectRatio = 16 / 9

      const videosToPlace = largeTiles.length

      // A fairly dumb iterative approach to find the maximum video size we can display while obeying our constraints (render area width and height)
      // There's probably a smarter math-y way to do this
      let maxWidth = null
      let numRows = 0
      while (true) {
        numRows++

        const videosPerRow = Math.ceil(videosToPlace / numRows)
        const availableWidth = width - videosPerRow * GAP_SPACE_BETWEEN_TILES
        const maxPossibleVideoWidth = availableWidth / videosPerRow

        const availableHeight = height - numRows * GAP_SPACE_BETWEEN_TILES
        const maxPossibleVideoHeight = availableHeight / numRows
        const dualAxisMaxPossibleWidth = Math.min(maxPossibleVideoWidth, maxPossibleVideoHeight * videoAspectRatio)

        if (maxWidth === null || dualAxisMaxPossibleWidth > maxWidth) {
          maxWidth = dualAxisMaxPossibleWidth
        } else {
          break
        }
      }
      if (desiredVideoWidth !== maxWidth) {
        setDesiredVideoWidth(maxWidth)
      }
    }
  }

  useRerenderOnScreenResize()

  const [largeTiles, audioTiles] = getTiles();
  return (
    <div css={css`display: flex; flex-direction: column; height: calc(100% - 12px); position: relative;`} ref={onTilesContainerRender}>
      <SessionToastForCallState callState={callState} />
      <div css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;
        padding: 12px 0px;
      `}>
        {largeTiles}
      </div>
      {!isMusicMuted &&
        <div css={css`display: flex; align-items: center;`}>
          {audioTiles}
        </div>
      }
    </div>
  );
}
