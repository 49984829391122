/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';
import { BUTTON_STYLES, FlowButton, Text, TEXT_STYLES } from '../components/flowComponents';
import { useParticles } from '../wrappers/ParticlesManager';
import { useDebounced } from '../utils';
import { useRef } from 'react';

const routes = [
  { path: '/admin-theme-clubs/', name: 'Themes as Clubs', requiresAdmin: true },
  { path: '/referrals-leaderboard/', name: 'Referrals Leaderboard', requiresAdmin: false },
  { path: '/components/', name: 'Design System Components', requiresAdmin: true },
  { path: '/usage-report/', name: 'Tagged User Usage Report', requiresAdmin: true },
  { path: '/admin-clubs-overview/', name: 'Clubs Overview', requiresAdmin: true },
  { path: '/demo-session/', name: 'Demo Session', requiresAdmin: false }
]

export const AdminDashboard = ({ }) => {
  return (
    <div css={css`display: flex; flex-direction: column; align-items: center; gap: 12px; margin-top: 32px; min-height: 100vh;`}>
      <Text customCss={css`text-align: center;`} style={TEXT_STYLES.APP_H3}>Friendly Collection of Helpful Links</Text>
      <div css={css`
        background: repeating-linear-gradient(
          -45deg,
          rgba(160, 187, 217, 0.2),
          rgba(160, 187, 217, 0.2) 30px,
          transparent 30px,
          transparent 60px
        );
        padding: 8px;
        border-radius: 8px;
        user-select: none;

        margin-bottom: 24px;
      `}>stripeys = admin-only view</div>
      {routes.map(({ path, name, requiresAdmin }, index) =>
        <Link to={path} key={index}>
          {requiresAdmin ? <SparklyButton>{name}</SparklyButton> : <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>{name}</FlowButton>}
        </Link>
      )}
    </div>
  )
}

const SparklyButton = ({ children, onClick }) => {
  const hoverAnimation = keyframes({
    'from': css`
      clip-path: polygon(-40% 0%, -20% 0%, -60% 100%, -80% 100%);
    `,
    'to': css`
      clip-path: polygon(160% 0%, 180% 0%, 140% 100%, 110% 100%);
    `
  })
  const hoverAnimationWide = keyframes({
    'from': css`
      clip-path: polygon(-60% 0%, 0% 0%, -40% 100%, -100% 100%);
    `,
    'to': css`
      clip-path: polygon(140% 0%, 200% 0%, 160% 100%, 90% 100%);
    `
  })

  const { emitParticleSet } = useParticles()
  const [emitAfterDelay, cancelEmit] = useDebounced(emitParticleSet, 1200)
  const ref = useRef(null)
  const onMouseEnter = () => {
    const { left: minX, right: maxX, top: minY, bottom: maxY } = ref.current.getBoundingClientRect()
    emitAfterDelay(
      {
        area: { minX, maxX, minY, maxY },
        particleLengthRange: { min: 5, max: 12 },
        width: 2,
        particleCountRange: { min: 8, max: 12 },
        emissionDuration: 500,
        rootColor: 'hsla( 41, 55%, 91%, .7)'
      },
      { mode: 'withinArea' }
    )
  }
  const onMouseLeave = () => cancelEmit()

  return (
    <div ref={ref}>
      <FlowButton onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} customCss={css`
        background: repeating-linear-gradient(
          -45deg,
          rgba(160, 187, 217, 0.2),
          rgba(160, 187, 217, 0.2) 30px,
          transparent 30px,
          transparent 60px
        );
        border: 1px solid #8e8e8e;
        color: black;
        position: relative;
        overflow: hidden;
        &:hover { color: white; }

        &:hover::after {
          background: linear-gradient(45deg, hsla(41, 63%, 49%, 0.7), white);
          width: 100%;
          aspect-ratio: 22/7;
          content: "";
          border-radius: 10%;
          animation: ${hoverAnimation} 0.6s linear both 0.5s;
          position: absolute;
          filter: blur(5px);
        }

        &:hover::before {
          background: linear-gradient(45deg, hsla(41, 63%, 49%, 0.35), rgba(255, 255, 255, 0.35));
          width: 100%;
          aspect-ratio: 22/7;
          content: "";
          border-radius: 10%;
          animation: ${hoverAnimationWide} 0.6s linear both 0.5s;
          position: absolute;
          filter: blur(5px);
        }
      `}>
        {children}
      </FlowButton>
    </div>
  )
}