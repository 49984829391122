import { USER_SESSION_LIST_MODES, CLUB_MEMBERSHIP_STATUSES, CLUB_PAYMENT_TYPES } from '../../functions/shared/constants'
import { PerformFetchReturnValues, PostRequestReturnValues, REQUEST_QUEUING_MODES, usePostRequest } from './helpers'
import { AdminBadgeClub, BadgeClub, Event, EventType, Goal, SessionVisibility, User, Host, FavoriteHost } from './types'



interface UpgradeUnauthedUserReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    userId: string,
    email: string,
  }) => Promise<PerformFetchReturnValues>,
  result: null | { upgradedUser: User }
}

export const useUpgradeUnauthedUser: () => UpgradeUnauthedUserReturnValues = () => {
  return usePostRequest("upgradeUnauthedUser")
}

interface CreateExtremelyUnauthedUserReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    userId: string,
    inviteCode: string,
    preSignupUserData: object,
    displayName?: string
  }) => Promise<PerformFetchReturnValues>,
  result: null | { userId: string } /** the submitted userId */
}

export const useCreateExtremelyUnauthedUser: () => CreateExtremelyUnauthedUserReturnValues = () => {
  return usePostRequest("createExtremelyUnauthedUser", { requiresAuth: false })
}


interface CheckVersionReturnValues extends PostRequestReturnValues {
  performFetch: (args: { version: string }) => Promise<PerformFetchReturnValues>,
  result: null | { updateNeeded: boolean } /** whether the client version is behind the server's and needs to be updated */
}
export const useCheckVersion: () => CheckVersionReturnValues = () => {
  return usePostRequest("checkVersion")
}

interface RequestThemeReturnValues extends PostRequestReturnValues {
  performFetch: (args: { title: string, description: string, agendaItems: Array<{ duration: number, name: string }> }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useRequestTheme: () => RequestThemeReturnValues = () => {
  return usePostRequest("createEventType")
}

interface CreateCustomizableSessionReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    startTime: string,
    eventTypeId: string,
    musicDescription: string,
    recurrences: number,
    hostId: string,
    customizationOptions: {
      duration: number,
      visibility: string,
      chatOnly: boolean,
      pomodoro: boolean,
      breakCheckIns: boolean,
    },
    hostGoalDescription: string,
    hostGoalEmoji: string,
   }) => Promise<PerformFetchReturnValues>,
  result: null | {
    createdSessionIds: Array<string>, /** an array of eventIds for the created sessions, including recurrences */
    errors: Array<{ intendedStartTime: number, error: string }>,
    ogImage: string // preview of OG image for sharing
  }
}
export const useCreateCustomizableSession: () => CreateCustomizableSessionReturnValues = () => {
  return usePostRequest("createCustomizableSession")
}


interface GetHostIncentiveInfoReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { completedSessions: number, pendingSessions: number, billingDate: null | number, displayProgress: boolean }
}
export const useGetHostIncentiveInfo: () => GetHostIncentiveInfoReturnValues = () => {
  return usePostRequest("getHostIncentiveInfo")
}

interface UpdateMusicReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, musicLink: string }) => Promise<PerformFetchReturnValues>,
  result: null | { title: string } /** the retrieved title for the linked playlist */
}
export const useUpdateMusic: () => UpdateMusicReturnValues = () => {
  return usePostRequest("updateSessionMusic")
}

interface SetReferralViewedReturnValues extends PostRequestReturnValues {
  performFetch: (args: { referralId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSetReferralViewed: () => SetReferralViewedReturnValues = () => {
  return usePostRequest("setReferralViewed")
}

interface GetReferralsDataReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | {referrals: Array<{
    description: string,
    applied: string | null,
    earned: string | null,
    action: string
  }>}
}
export const useGetReferralsData: () => GetReferralsDataReturnValues = () => {
  return usePostRequest("getReferralsData")
}

interface GetUpcomingOnDemandSlotReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { onDemandSlot: number }
}
export const useGetUpcomingOnDemandSlot: () => GetUpcomingOnDemandSlotReturnValues = () => {
  return usePostRequest("getUpcomingOnDemandSlot", { requiresAuth: false })
}

interface GetHostProfileReturnValues extends PostRequestReturnValues {
  performFetch: (args: { slug: string }) => Promise<PerformFetchReturnValues>,
  result: null | { somethingComplicatedProbably: any } /** TODO: make this more descriptive  */
}
export const useGetHostProfile: () => GetHostProfileReturnValues = () => {
  return usePostRequest("getHostProfile", { requiresAuth: false })
}

interface GetActiveHostsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { page?: number, pageSize?: number }) => Promise<PerformFetchReturnValues>,
  result: null | { hosts: Array<Host>, totalCount: number }
}

export const useGetActiveHosts: () => GetActiveHostsReturnValues = () => {
  return usePostRequest("getActiveHosts", { requiresAuth: false })
}

interface GetFavoriteHostsReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { favoriteHosts: Array<FavoriteHost> }
}

export const useGetFavoriteHosts: () => GetFavoriteHostsReturnValues = () => {
  return usePostRequest("getFavoriteHosts")
}

interface UpdateSessionTitleReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, title: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useUpdateSessionTitle: () => UpdateSessionTitleReturnValues = () => {
  return usePostRequest("updateSessionTitle")
}

interface SignupWithoutBookingReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    displayName: string,
    email: string,
    userId: string,
    inviteCode: string,
    guestPassId: string,
    preSignupUserData: {
      makingTimeFor?: string,
      specificGoal?: string,
      helpMeWith?: string,
      welcomeType?: 'home' | 'interstitial' | 'none' | 'noBadges' | 'simpleHome' | 'hideExcessSessions' | 'recommendedSessions' | 'collapseTimeSlots' | 'tinySessionCards' | 'score3Sessions' | 'timeOnboarding' | 'intentionOnboarding'
      watchedIntroVideo?: boolean,
    }
  }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSignupWithoutBooking: () => SignupWithoutBookingReturnValues = () => {
  return usePostRequest("signupWithoutBooking", { requiresAuth: false })
}

interface ValidateInviteCodeReturnValues extends PostRequestReturnValues {
  performFetch: (args: { inviteCode: string }) => Promise<PerformFetchReturnValues>,
  result: null | { codeIsValid: boolean, invalidReason: null | string }
}
export const useValidateInviteCode: () => ValidateInviteCodeReturnValues = () => {
  return usePostRequest("validateInviteCode", { requiresAuth: false })
}

interface UpdateUserReturnValues extends PostRequestReturnValues {
  performFetch: (args: { updateObject: {
    // if typescript worked on the BE I think we could infer this type from USER_UPDATE_KEYS, which would be a lot less prone to drift from the BE code
    // not sure if it's possible atm though (without BE typescript)
    firstName?,
    lastName?,
    fullName?,
    displayName?,
    emojis?,
    location?,
    pronouns?,
    pronunciation?,
    company?,
    flowActivities?,
    additionalCalendarEmails?,
    email?,
    makingTimeFor?,
    specificGoal?,
    timezone?,
    timezoneOffset?,
    hostStatus?,
    signupOrigin?: 'clubPage',
    twitter?,
    linkedin?,
    linkUrl?,
    linkText?,
    headline?,
    hideInPublicDirectory?,
  } }) => Promise<PerformFetchReturnValues>,
  result: null | { result: Object, errorFields: Array<{ key: string, message: string }> }
}
export const useUpdateUser: () => UpdateUserReturnValues = () => {
  return usePostRequest("updateUser")
}

interface SetUserPreferencesReturnValues extends PostRequestReturnValues {
  performFetch: (args: { newPreferenceData: {
    spaceWelcomeMessageSeen?: boolean,
    sessionTimeFilters?: {
      Morning?: boolean,
      Afternoon?: boolean,
      Night?: boolean
    },
    sessionDurationFilters?: {
      30?: boolean,
      60?: boolean,
      90?: boolean,
      120?: boolean,
      180?: boolean
    },
    sessionThemeFilters?: {
      showChatOnly?: boolean,
      showNonChatOnly?: boolean,
      showPomodoro?: boolean,
      showNonPomodoro?: boolean,
      showBreakCheckIns?: boolean,
      showNonBreakCheckIns?: boolean,
    },
    weeklyHoursGoal?: number,
    monthlyIntention?: string,
    hideHostFollowButton?: boolean,
    musicSharingDefaultTab?: number,
    openSpotifyInNewWindow?: boolean,
    disablePopConfetti?: boolean,
    optIntoHostFavoriteEmails?: boolean,
    sessionCreationCustomizationSettings?: {
      duration: number,
      visibility: SessionVisibility,
      chatOnly: boolean,
      pomodoro: boolean,
      breakCheckIns: boolean,
      eventTypeId: string,
      recurrences: 1 | 2 | 3 | 4,
      customizeEnabled: boolean
    },
    disableCalendarInvites: boolean,
    hideDisplayNameEmojis: boolean,
    setOrDismissedThemeClubsBanner?: boolean,
  } }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSetUserPreferences: () => SetUserPreferencesReturnValues = () => {
  return usePostRequest("setUserPreferences", { queuingMode: REQUEST_QUEUING_MODES.REPLACE })
}

interface GetNextJoinableSessionForNewUserReturnValues extends PostRequestReturnValues {
  performFetch: (args: {}) => Promise<PerformFetchReturnValues>,
  result: null | { nextSession: Event, isNow: boolean }
}

export const useGetNextJoinableSessionForNewUser: () => GetNextJoinableSessionForNewUserReturnValues = () => {
  return usePostRequest("getNextJoinableSessionForNewUser", { requiresAuth: false })
}

interface GetRecommendedSessionForUserReturnValues extends PostRequestReturnValues {
  // timeFilter should also really be inferred from TimeFilterOptions in constants, but rip typescript
  performFetch: (args: { timeFilter: 'Morning' | 'Afternoon' | 'Night' | null, timezone: string }) => Promise<PerformFetchReturnValues>,
  result: null | { recommendedSession: any }
}
export const useGetRecommendedSessionForUser: () => GetRecommendedSessionForUserReturnValues = () => {
  return usePostRequest("getRecommendedSessionForUser", { requiresAuth: false })
}

interface GetHostFeedbackReturnValues extends PostRequestReturnValues {
  performFetch: (args: {}) => Promise<PerformFetchReturnValues>,
  result: null | { eventsWithFeedback: Array<{
    eventData: { id: string, title: string, start: number, musicDescription: string },
    feedback: { name: string, hostFeedback: string }
  }> }
}
export const useGetHostFeedback: () => GetHostFeedbackReturnValues = () => {
  return usePostRequest("getHostFeedback")
}

interface GetUserHoursBookedThisWeekReturnValues extends PostRequestReturnValues {
  performFetch: (args: {}) => Promise<PerformFetchReturnValues>,
  result: null | { hoursBooked: number }
}
export const useGetUserHoursBookedThisWeek: () => GetUserHoursBookedThisWeekReturnValues = () => {
  return usePostRequest("getUserHoursBookedThisWeek")
}

interface GetSchedulePageReturnValues extends PostRequestReturnValues {
  performFetch: (args: { numSpaces?: number, inviteCode?: string, guestPassId?: string, limitEvents?: number }) => Promise<PerformFetchReturnValues>,
  result: null | {
    numCurrentlyWorking: number,
    numCurrentSessions: number,
    badgeMembershipsByUser: {
      [property: string]: Array<{ badgeId: string, name: string }>
    },
    sessions: Array<Event>,
    canCreateSessions: boolean
  }
}
export const useGetSchedulePage: () => GetSchedulePageReturnValues = () => {
  return usePostRequest("getSchedulePage", { requiresAuth: false })
}

interface getUpcomingEventsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { limitEvents?: number, theme?: string, source?: string }) => Promise<PerformFetchReturnValues>,
  result: null | {
    sessions: Array<Event>,
  }
}

export const useGetUpcomingEvents: () => getUpcomingEventsReturnValues = () => {
  return usePostRequest("getUpcomingEvents", { requiresAuth: false })
}


interface GetCreatePageReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | {
    hosts: Array<{ displayName: string, id: string, timezone: string }>,
    eventTypes: Array<EventType>,
    onDemandCreationInfo: { creationAllowedAt: null | number, createOnDemandSessionButtonEnabled: boolean },
    claimableSessions: Array<Event>,
    canCreateSessions: boolean,
    creatableSessionVisibilities: Array<SessionVisibility>
  }
}
export const useGetCreatePage: () => GetCreatePageReturnValues = () => {
  return usePostRequest("getCreatePageV2", { requiresAuth: false })
}



interface SpaceData {
  visitId: string,
  visit: any,
  space: any,
  currSpaceVisits: any,
  nextUpcomingParticipant: any
}
interface VisitSpaceReturnValues extends PostRequestReturnValues {
  performFetch: (args: { spaceId?: string, goals: Array<{ completedAt: number, text: string }> }) => Promise<PerformFetchReturnValues>,
  result: null | SpaceData
}
export const useVisitSpace: () => VisitSpaceReturnValues = () => {
  return usePostRequest("visitSpace", { requiresAuth: false })
}


interface StartSpaceTimerReturnValues extends PostRequestReturnValues {
  performFetch: (args: { visitId: string, timerLengthMs: number }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useStartSpaceTimer: () => StartSpaceTimerReturnValues = () => {
  return usePostRequest("startSpaceTimer")
}

interface StopSpaceTimerReturnValues extends PostRequestReturnValues {
  performFetch: (args: { visitId: string, shouldStartBreak: boolean }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useStopSpaceTimer: () => StopSpaceTimerReturnValues = () => {
  return usePostRequest("stopSpaceTimer")
}

interface SpaceCompleteGoalReturnValues extends PostRequestReturnValues {
  performFetch: (args: { visitId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSpaceCompleteGoal: () => SpaceCompleteGoalReturnValues = () => {
  return usePostRequest("spaceCompleteGoal")
}

interface AddSpaceTimerFlowScoreReturnValues extends PostRequestReturnValues {
  performFetch: (args: { visitId: string, flowScore: number }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useAddSpaceTimerFlowScore: () => AddSpaceTimerFlowScoreReturnValues = () => {
  return usePostRequest("addSpaceTimerFlowScore")
}

interface InitiateHighFiveWithUserReturnValues extends PostRequestReturnValues {
  performFetch: (args: { spaceId: string, recipientId: string, clientSpaceEventId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useInitiateHighFiveWithUser: () => InitiateHighFiveWithUserReturnValues = () => {
  return usePostRequest("initiateHighFiveWithUser")
}

interface HighFiveFromEventReturnValues extends PostRequestReturnValues {
  performFetch: (args: { spaceId: string, spaceEventId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useHighFiveFromEvent: () => HighFiveFromEventReturnValues = () => {
  return usePostRequest("highFiveFromEvent")
}

interface SendSpaceDesktopReminderReturnValues extends PostRequestReturnValues {
  performFetch: (args: { spaceId: string, recipientEmail: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSendSpaceDesktopReminder: () => SendSpaceDesktopReminderReturnValues = () => {
  return usePostRequest("sendSpaceDesktopReminder", { requiresAuth: false })
}

interface SaveUserGoogleCalendarReturnValues extends PostRequestReturnValues {
  performFetch: (args: { code: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSaveUserGoogleCalendar: () => SaveUserGoogleCalendarReturnValues = () => {
  return usePostRequest("saveUserGoogleCalendar")
}

interface GetUserGoogleCalendarEventsReturnValues extends PostRequestReturnValues {
  performFetch: (args: {}) => Promise<PerformFetchReturnValues>,
  result: null | { googleCalendarData: any[] }
}
export const useGetUserGoogleCalendarEvents: () => GetUserGoogleCalendarEventsReturnValues = () => {
  return usePostRequest("getUserGoogleCalendarEvents")
}

interface GetSessionRecommendationsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { minTimestamp: number, maxTimestamp: number }) => Promise<PerformFetchReturnValues>,
  result: null | { recommendations: any[] }
}
export const useGetSessionRecommendations: () => GetSessionRecommendationsReturnValues = () => {
  return usePostRequest("getSessionsToBookForNewUser", { requiresAuth: false })
}


interface RequestHostTBDSessionAndBookReturnValues extends PostRequestReturnValues {
  performFetch: (args: { startTimestamp: number}) => Promise<PerformFetchReturnValues>,
  result: null | { eventId: string }
}
export const useRequestHostTBDSessionAndBook: () => RequestHostTBDSessionAndBookReturnValues = () => {
  return usePostRequest("requestHostTBDSessionAndBook")
}


/* In-Session endpoints */

interface GetDailyMeetingTokenReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { meetingToken: string }
}
export const useGetDailyMeetingToken: () => GetDailyMeetingTokenReturnValues = () => {
  return usePostRequest("getDailyMeetingToken")
}

interface UpdateSessionGoalsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, goals: Array<{ completedAt: null | number, text: string}>, updateCalendarEvent: boolean }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useUpdateSessionGoals: () => UpdateSessionGoalsReturnValues = () => {
  return usePostRequest("updateSessionGoals")
}

interface JoinSessionReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, goals: Array<{ completedAt: null | number, text: string }>, displayName: string, inviteCode: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useJoinSession: () => JoinSessionReturnValues = () => {
  return usePostRequest("joinSession")
}

interface SendChatMessageReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, message: string, messageId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSendChatMessage: () => SendChatMessageReturnValues = () => {
  return usePostRequest("sendChatMessage")
}

interface SendChatMessageAttachmentReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, attachment: { value: string, type: 'emoji' }, messageId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSendChatMessageAttachment: () => SendChatMessageAttachmentReturnValues = () => {
  return usePostRequest("sendChatMessageAttachment", { queuingMode: REQUEST_QUEUING_MODES.APPEND })
}

interface RemoveChatMessageAttachmentReturnValues extends PostRequestReturnValues {
  performFetch: (args: { attachmentId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useRemoveChatMessageAttachment: () => RemoveChatMessageAttachmentReturnValues = () => {
  return usePostRequest("removeChatMessageAttachment", { queuingMode: REQUEST_QUEUING_MODES.APPEND })
}

interface GetPostFlowInfoReturnValues extends PostRequestReturnValues {
  performFetch: (args: { currentEventId: string }) => Promise<PerformFetchReturnValues>,
  result: null | {
    hostData: { 
      image64: string,
      displayName: string,
      slug: string,
      hostId: string,
      isFollowed: boolean,
      hideHostFollowButton: boolean,
    },
    nextUpcomingParticipant: null | Object,
    eventAttendanceRun: boolean,
    totalSessions: number
    hostedSessions: number,
    spaces: Array<any>,
    eventData: Event,
    canCreateSessions: boolean,
    badgesUserCanSubmitSharedWinsFor: Array<{ id: string, clubPageSlug: string, emoji: string, clubTitle: string, winsPlaceholders: Array<string> }>
  }
}
export const useGetPostFlowInfo: () => GetPostFlowInfoReturnValues = () => {
  return usePostRequest("getPostFlowInfo")
}

interface SubmitPostFlowDataReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, property: string, value: string | number, updateUserFlowScore: boolean }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSubmitPostFlowData: () => SubmitPostFlowDataReturnValues = () => {
  return usePostRequest("submitPostFlowData")
}

interface SubmitSharedWinReturnValues extends PostRequestReturnValues {
  performFetch: (args: { badgeId: string, text: string, eventId?: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSubmitSharedWin: () => SubmitSharedWinReturnValues = () => {
  return usePostRequest("submitSharedWin")
}

interface getPostFlowMilestoneInfoReturnValues extends PostRequestReturnValues {
  performFetch: (args: { currentEventId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { isMilestone: boolean, isFirstHostedSession: boolean, inviteLink: string | null, imageUrl: string | null }
}
export const useGetPostFlowMilestoneInfo: () => getPostFlowMilestoneInfoReturnValues = () => {
  return usePostRequest("getPostFlowMilestoneInfo")
}

interface getPostFirstFlowInfoReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { recommendedSession: null | Event }
}
export const useGetPostFirstFlowInfo: () => getPostFirstFlowInfoReturnValues = () => {
  return usePostRequest("getPostFirstFlowInfo")
}

interface toggleSessionHostedMusicPlaybackReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, togglingTo: boolean, playlistId: null|string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useToggleSessionHostedMusicPlayback: () => toggleSessionHostedMusicPlaybackReturnValues = () => {
  return usePostRequest("toggleSessionHostedMusicPlayback")
}

interface toggleSessionAutomutingReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string, togglingTo: boolean }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useToggleSessionAutomuting: () => toggleSessionAutomutingReturnValues = () => {
  return usePostRequest("toggleSessionAutomuting")
}

interface getActiveHostedMusicPlaylistsReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { playlists: Array<{ id: string, name: string}> }
}

export const useGetActiveHostedMusicPlaylists: () => getActiveHostedMusicPlaylistsReturnValues = () => {
  return usePostRequest("getActiveHostedMusicPlaylists")
}

/* end In-Session endpoints */

/* begin billing endpoints */
interface CreateCheckoutSessionReturnValues extends PostRequestReturnValues {
  performFetch: (args: { period: "monthly"|"yearly" }) => Promise<PerformFetchReturnValues>,
  result: null | { sessionId: string }
}

export const useCreateCheckoutSession: () => CreateCheckoutSessionReturnValues = () => {
  return usePostRequest("billing-createCheckoutSession")
}

interface CreateCheckoutSessionForClubReturnValues extends PostRequestReturnValues {
  performFetch: (args: { clubId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { sessionId: string }
}

export const useCreateCheckoutSessionForClub: () => CreateCheckoutSessionForClubReturnValues = () => {
  return usePostRequest("billing-createCheckoutSessionForClub")
}

/* end billing ednpionts */

interface Club {
  name: string,
  description: string,
  clubHostDescription: string,
  image: string,
  hostImage: string,
  hostSlug: string,
  fullName: string,
  duration: string,
  startDate: Date | null,
  endDate: Date | null,
  paymentType: keyof typeof CLUB_PAYMENT_TYPES,
  sessions: Array<{startTime: Date, duration: number, title: string}>,
  paymentAmount?: number | null,
  resourcesDescription: Array<string>,
  status: keyof typeof CLUB_MEMBERSHIP_STATUSES,
}

interface GetActiveClubsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { onlyGetUserClubs: boolean }) => Promise<PerformFetchReturnValues>,
  result: null | { clubs: Array<Club> }
}

export const useGetActiveClubs: () => GetActiveClubsReturnValues = () => {
  return usePostRequest("getActiveClubs", { requiresAuth: false })
}

interface GetClubReturnValues extends PostRequestReturnValues {
  performFetch: (args: { slug: string }) => Promise<PerformFetchReturnValues>,
  result: null | { club: Club }
}

export const useGetClub: () => GetClubReturnValues = () => {
  return usePostRequest("getClub", { requiresAuth: false })
}

interface GetClubSessionsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { slug: string }) => Promise<PerformFetchReturnValues>,
  result: null | { sessions: Array<Event> }
}

export const useGetClubSessions: () => GetClubSessionsReturnValues = () => {
  return usePostRequest("getClubSessions")
}

interface IndicateInterestInClubReturnValues extends PostRequestReturnValues {
  performFetch: (args: { clubId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { success: boolean }
}

export const useIndicateInterestInClub: () => IndicateInterestInClubReturnValues = () => {
  return usePostRequest("indicateInterestInClub")
}

interface JoinClubReturnValues extends PostRequestReturnValues {
  performFetch: (args: { clubId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { success: boolean }
}

export const useJoinClub: () => JoinClubReturnValues = () => {
  return usePostRequest("joinClub")
}

interface CreateSessionsForClubReturnValues extends PostRequestReturnValues {
  performFetch: (args: { clubId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { createdSessions: Array<{ title, startTime, id }>, skippedSessions: Array<string> }
}

export const useCreateSessionsForClub: () => CreateSessionsForClubReturnValues = () => {
  return usePostRequest("createSessionsForClub")
}

interface EmailUsersWhoHaveFavoritedHostAboutNewClubReturnValues extends PostRequestReturnValues {
  performFetch: (args: { clubId: string, dryRun: boolean }) =>
  Promise<PerformFetchReturnValues>,
  result: null | { sent: number, notSent: number }
}

export const useEmailUsersWhoHaveFavoritedHostAboutNewClub: () => EmailUsersWhoHaveFavoritedHostAboutNewClubReturnValues = () => {
  return usePostRequest("emailUsersWhoHaveFavoritedHostAboutNewClub")
}

interface UpdateSessionStartAndEndTimesReturnValues extends PostRequestReturnValues {
  performFetch: (args: { sessionId: string, newStartTime: string, explanation: String }) => Promise<PerformFetchReturnValues>,
  result: null | { success: boolean }
}

export const useUpdateSessionStartAndEndTimes: () => UpdateSessionStartAndEndTimesReturnValues = () => {
  return usePostRequest("updateSessionStartAndEndTimes")
}

interface GetMembersForClubsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { clubId: string }) => Promise<PerformFetchReturnValues>,
  result: null | Array<{ clubId: string, members: Array<User> }>
}

export const useGetMembersForClubs: () => GetMembersForClubsReturnValues = () => {
  return usePostRequest("getMembersForClubs")
}


/* Admin User Stats endpoints */

interface GetSuggestedTagsForStatsReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { tags: Array<{ tag: string, recentSessions: number }> }
}
export const useGetSuggestedTagsForStats: () => GetSuggestedTagsForStatsReturnValues = () => {
  return usePostRequest("getSuggestedTagsForStats")
}

interface GetTaggedSessionAndUserStatsReturnValues extends PostRequestReturnValues {
  performFetch: (args: { tag: string, dateInfo: { startDate: string, endDate: string } }) => Promise<PerformFetchReturnValues>,
  result: null | { users: Array<any>, events: Array<any> }
}
export const useGetTaggedSessionAndUserStats: () => GetTaggedSessionAndUserStatsReturnValues = () => {
  return usePostRequest("getTaggedSessionAndUserStats", { queuingMode: REQUEST_QUEUING_MODES.REPLACE })
}

interface GetBadgedUserStatsReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { badgedUserStats: any }
}
export const useGetBadgedUserStats: () => GetBadgedUserStatsReturnValues = () => {
  return usePostRequest("getBadgedUserStats")
}

/* end Admin User Stats endpoints */


interface GetBillingCustomerPortalUrlReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { url: null | string }
}
export const useGetBillingCustomerPortalUrl: () => GetBillingCustomerPortalUrlReturnValues = () => {
  return usePostRequest("billing-customerPortal")
}


/* Booking-Related endpoints */

interface BookSessionReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    eventId: string,
    displayName?: string,
    tags?: Array<string>,
    shouldBookAsHost: boolean,
    musicDescription: string
  }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useBookSession: () => BookSessionReturnValues = () => {
  return usePostRequest("inviteUserToEvent", { requiresAuth: false })
}

interface BookMultipleSessionsReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    sessionIds: string[],
    clubSlug?: string,
  }) => Promise<PerformFetchReturnValues>,
  result: null | { sessionsBooked: Array<{ success: boolean, error?: string }> }
}

export const useBookMultipleSessions: () => BookMultipleSessionsReturnValues = () => {
  return usePostRequest("bookMultipleSessions")
}

interface RemoveUserFromEventReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    eventId: string,
    reason?: string,
    otherReason?: string,
    canceledBy?: string
  }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useRemoveUserFromEvent: () => RemoveUserFromEventReturnValues = () => {
  return usePostRequest("removeUserFromEvent")
}

interface CancelHostSessionReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    eventId: string,
    reason: string,
    otherReason: string
  }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useCancelHostSession: () => CancelHostSessionReturnValues = () => {
  return usePostRequest("cancelHostSession")
}

interface ClaimHostReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    eventId: string,
    musicDescription?: string,
    source?: any // ??? what is source for,
    claimingFromNoShowingHost: boolean
  }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useClaimHost: () => ClaimHostReturnValues = () => {
  return usePostRequest("claimHost")
}

/* end Booking-Related endpoints */


/* Host Upgrade endpoints */

interface StartHostUpgradeProcessReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { hostUserData: User }
}
export const useStartHostUpgradeProcess: () => StartHostUpgradeProcessReturnValues = () => {
  return usePostRequest("startHostUpgradeProcess")
}

interface UpdateHostReturnValues extends PostRequestReturnValues {
  performFetch: (args: { updateObject: {
    bio?: string,
    slug?: string,
    headline?: string,
    musicTitles?: string,
    location?: string,
    flowActivities?: string,
  }}) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useUpdateHost: () => UpdateHostReturnValues = () => {
  return usePostRequest("updateHost")
}

// need to upgrade usePostRequest to support non-json encoded requests for this to be useful
// interface UploadHostPhotoReturnValues extends PostRequestReturnValues {
//   performFetch: (args: { uploads: { profilePhoto: any } }) => Promise<PerformFetchReturnValues>,
//   result: null
// }
// export const useUploadHostPhoto: () => UploadHostPhotoReturnValues = () => {
//   return usePostRequest("uploadHostPhoto")
// }

interface RegisterHostUpgradeFormCompletionReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null
}
export const useRegisterHostUpgradeFormCompletion: () => RegisterHostUpgradeFormCompletionReturnValues = () => {
  return usePostRequest("registerHostUpgradeFormCompletion")
}

/* end Host Upgrade endpoints */

interface GetEventsForUserReturnValues extends PostRequestReturnValues {
  performFetch: (args: { startAtParticipantId?: string, startAfterParticipantId?: string, endBeforeParticipantId?: string, listMode: keyof typeof USER_SESSION_LIST_MODES, userId?: string, pageSize?: number }) => Promise<PerformFetchReturnValues>,
  result: null | { events: Array<Event>, morePages: boolean }
}
export const useGetEventsForUser: () => GetEventsForUserReturnValues = () => {
  return usePostRequest("getEventsForUser")
}

interface GetEventDetailsReturnValues extends PostRequestReturnValues {
  performFetch: ( args: { eventId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { event: Event }
}
export const useGetEventDetails: () => GetEventDetailsReturnValues = () => {
  return usePostRequest("getEventDetails", { requiresAuth: false })
}

interface GetMostRecentParticipantGoalsForUser extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { start: number, goals: Array<Goal> }
}

export const useGetMostRecentParticipantGoalsForUser: () => GetMostRecentParticipantGoalsForUser = () => {
  return usePostRequest("getMostRecentParticipantGoalsForUser")
}

/* Badge endpoints */

interface ApplyBadgeReturnValues extends PostRequestReturnValues {
  performFetch: (args: { badgeId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useApplyBadge: () => ApplyBadgeReturnValues = () => {
  return usePostRequest("applyBadge")
}

interface GetBadgesForUsersReturnValues extends PostRequestReturnValues {
  performFetch: (args: { userIds: Array<string> }) => Promise<PerformFetchReturnValues>,
  result: null | {
    badgeMembershipsByUser: {
      [property: string]: Array<{ badgeId: string, name: string }>
    },
    badges: Array<{ id: string, name: string }>
  }
}
export const useGetBadgesForUsers: () => GetBadgesForUsersReturnValues = () => {
  return usePostRequest("getBadgesForUsers", { requiresAuth: false })
}

interface SetJoinedBadgesReturnValues extends PostRequestReturnValues {
  performFetch: (args: { badgeIds: Array<string> }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSetJoinedBadges: () => SetJoinedBadgesReturnValues = () => {
  return usePostRequest("setJoinedBadges")
}

interface GetClubPageReturnValues extends PostRequestReturnValues {
  performFetch: (args: { badgeSlug: string, requestType: "basic" | "full" }) => Promise<PerformFetchReturnValues>,
  result: null | {
    badgeId: string,
    emoji: string,
    viewingUserJoinedClub: boolean,
    numMembers: number,
    sharedCounter: number,
    sharedCounterLabel: string,
    theme: string,
    badgedHostUsers: Array<User & { numUpcomingSessions: number }>,
    upcomingSessions: Array<Event>,
    exclusiveSessions: Array<Event>,
    showExclusiveSessions: boolean,
    sessionVisibilityTag?: string,
    sharedWins: Array<{
      id: string,
      text: string,
      userId: string,
      userDisplayName: string,
      highFives: number,
      session: {
        start: number,
        hostName: string,
        hostSlug: string
      } | null
    }>,
    containsFullData: boolean
  } & BadgeClub
}
export const useGetClubPage: () => GetClubPageReturnValues = () => {
  return usePostRequest("getClubPage", { requiresAuth: false })
}

interface SendClubPageSharedWinHighFiveReturnValues extends PostRequestReturnValues {
  performFetch: (args: { winId: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useSendClubPageSharedWinHighFive: () => SendClubPageSharedWinHighFiveReturnValues = () => {
  return usePostRequest("sendClubPageSharedWinHighFive", { queuingMode: REQUEST_QUEUING_MODES.APPEND })
}

/* end Badge endpoints */

/* Host Following endpoints */

interface FollowHostReturnValues extends PostRequestReturnValues {
  performFetch: (args: { hostId: string, followerEmail?: string, followerName?: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useFollowHost: () => FollowHostReturnValues = () => {
  return usePostRequest("followHost", { requiresAuth: false })
}

interface UnfollowHostReturnValues extends PostRequestReturnValues {
  performFetch: (args: { hostId: string, followerEmail?: string }) => Promise<PerformFetchReturnValues>,
  result: null
}
export const useUnfollowHost: () => UnfollowHostReturnValues = () => {
  return usePostRequest("unfollowHost", { requiresAuth: false })
}

interface UnfollowHostByFollowIdReturnValues extends PostRequestReturnValues {
  performFetch: (args: { hostFollowId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { unsubscribed: boolean }
}
export const useUnfollowHostByFollowId: () => UnfollowHostByFollowIdReturnValues = () => {
  return usePostRequest("unfollowHostByFollowId", { requiresAuth: false })
}

interface ToggleHostFollowCalendarInvitesReturnValues extends PostRequestReturnValues {
  performFetch: (args: { hostId: string, receiveCalendarInvites: boolean }) => Promise<PerformFetchReturnValues>,
  result: null
}

export const useToggleHostFollowCalendarInvites: () => ToggleHostFollowCalendarInvitesReturnValues = () => {
  return usePostRequest("toggleHostFollowCalendarInvites")
}

/* end Host Following endpoints */


interface SetupDemoSessionReturnValues extends PostRequestReturnValues {
  performFetch: () => Promise<PerformFetchReturnValues>,
  result: null | { meetingToken: string, dailyRoomName: string }
}
export const useSetupDemoSession: () => SetupDemoSessionReturnValues = () => {
  return usePostRequest("setupDemoSession")
}

/* Setup a Lounge Session */
interface SetupLoungeSessionReturnValues extends PostRequestReturnValues {
  performFetch: (args: { eventId: string }) => Promise<PerformFetchReturnValues>,
  result: null | { meetingToken: string, dailyRoomName: string }
}
export const useSetupLoungeSession: () => SetupLoungeSessionReturnValues = () => {
  return usePostRequest("setupLoungeSession")
}

interface GetReferralsLeaderboardDataReturnValues extends PostRequestReturnValues {
  performFetch: (args: { monthOffset: number }) => Promise<PerformFetchReturnValues>,
  result: null | { usersWithReferrals: Array<{ userId: string, invitesAccepted: number }> }
}
export const useGetReferralsLeaderboardData: () => GetReferralsLeaderboardDataReturnValues = () => {
  return usePostRequest("getReferralsLeaderboardData", { requiresAuth: false })
}

/* Phone Free Participant Endpoints */
interface UpdatePhoneFreeParticipantReturnValues extends PostRequestReturnValues {
  performFetch: (args: { participantId: string, updateObject: {
    phoneFreeOpened?: boolean,
    phoneFreeStarted?: boolean,
    phoneFreeLastActive?: boolean,
    phoneFreeEnded?: boolean,
    phoneFreeElapsedTime: number,
  }}) => Promise<PerformFetchReturnValues>,
  result: null | { event: Event, participant: any }
}

export const useUpdatePhoneFreeParticipant: () => UpdatePhoneFreeParticipantReturnValues = () => {
  return usePostRequest("updatePhoneFreeParticipant", { requiresAuth: false })
}

/* End Phone Free Participant Endpoints */

/* Moderation Endpoints */

interface CreateNewChatMessageReportReturnValues extends PostRequestReturnValues {
  performFetch(args: { 
    chatMessageId: string,
    senderId: string,
    sessionId: string,
    reason: string,
    explanation: string
  }): Promise<PerformFetchReturnValues>,
  result: null
}

export const useCreateNewChatMessageReport: () => CreateNewChatMessageReportReturnValues = () => {
  return usePostRequest("createNewChatMessageReport")
}
interface CreateNewUserInSessionReportReturnValues extends PostRequestReturnValues {
  performFetch(args: {
    reportedUserId: string,
    sessionId: string,
    reason: string,
    explanation?: string,
    attachmentUrl?: string,
  }): Promise<PerformFetchReturnValues>,
  result: null
}

export const useCreateNewUserInSessionReport: () => CreateNewUserInSessionReportReturnValues = () => {
  return usePostRequest("createNewUserInSessionReport")
}


/* End Moderation Endpoints */

interface AdminThemeClubsDataReturnValues extends PostRequestReturnValues {
  performFetch: (args: {
    start: number,
    end: number,
  }) => Promise<PerformFetchReturnValues>,
  result: null | { clubs: Array<AdminBadgeClub> }
}

export const useAdminThemeClubsData: () => AdminThemeClubsDataReturnValues = () => {
  return usePostRequest("getAdminThemeClubsData")
}